import localConfig from './config-local.json'
import devConfig from './config-dev.json'
import stagingConfig from './config-staging.json'
import prodConfig from './config-prod.json'
import qaConfig from './config-qa.json'
import eksConfig from './config-eks-prod.json'

function determineConfig() {
  switch (process.env.GATSBY_DEPLOY_ENV) {
    case 'www.bizy.com':
      return prodConfig
    case 'dev-www.bizy.com':
      return devConfig
    case 'staging-www.bizy.com':
      return stagingConfig
    case 'qa-www.bizy.com':
      return qaConfig
    case 'www.eks.bizy.com':
      return eksConfig
    default:
      return localConfig
  }
}

function determineEnv() {
  switch (process.env.GATSBY_DEPLOY_ENV) {
    case 'www.bizy.com':
      return 'prod'
    case 'dev-www.bizy.com':
      return 'dev'
    case 'staging-www.bizy.com':
      return 'staging'
    case 'qa-www.bizy.com':
      return 'qa'
    case 'www.eks.bizy.com':
      return 'eks-prod'
    default:
      return 'local'
  }
}

export const config = determineConfig()
export const env = determineEnv()
