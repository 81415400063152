import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { withPrefix } from 'gatsby'

// Config
import { config, env } from '../config'

// Styling
import '../scss/master.scss'

function Layout({ children, pageName }) {
  return (
    <div className={pageName}>
      <Helmet>
        <title>Bizy</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        <meta name="description" content="Smart &amp; easy group decision making" />

        <link rel="apple-touch-icon" sizes="180x180" href={withPrefix(`/favicons/${env}/apple-touch-icon.png`)} />
        <link rel="icon" type="image/x-icon" sizes="any" href={withPrefix(`/favicons/${env}/favicon.ico`)} />
        <link rel="manifest" href={withPrefix(`/favicons/${env}/site.webmanifest`)} />
        <link rel="mask-icon" href={withPrefix(`/favicons/${env}/safari-pinned-tab.svg`)} color="#5bbad5" />

        <meta name="msapplication-TileColor" content="#0a6cff" />
        <meta name="theme-color" content="#0a6cff" />
        <meta name="msapplication-config" content={withPrefix(`/favicons/${env}/browserconfig.xml`)} />

        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="625" />
        <meta property="og:description" content="Smart &amp; easy group decision making" />
        <meta property="og:title" content="Bizy" />
        <meta property="og:image" content={withPrefix(`/favicons/${env}/meta.png`)} />
        <meta property="og:url" content={`${config.websiteUrl}`} />

        <meta name="twitter:domain" content={`${config.websiteUrl}`} />
        <meta name="twitter:card" content="photo" />
        <meta name="twitter:site" content="@bizy" />
        <meta name="twitter:creator" content="@bizy" />
        <meta name="twitter:title" content="Bizy" />
        <meta name="twitter:description" content="Smart &amp; easy group decision making" />
      </Helmet>

      {children}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
  pageName: PropTypes.string
}

export default Layout
